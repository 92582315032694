import * as React from 'react'

import { ProductBlock } from '@thg-commerce/enterprise-components'
import { i18n, useFormattableI18nProperty } from '@thg-commerce/enterprise-core'
import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import {
  SwatchShape,
  SwatchSize,
} from '@thg-commerce/gravity-elements/Swatch/types'
import { GridItem } from '@thg-commerce/gravity-system'

import { ProductRecommendationRail } from './ProductRecommendationRail'
import {
  ButtonWrapper,
  ProductContainer,
  Subtitle,
  Title,
  ViewMoreButton,
} from './styles'
import { ProductProps, SectionPeekProps } from './types'

const showMoreEvent = () => {
  return pushToDataLayer({
    type: 'elysiumEvent',
    eventData: {
      eventAction: 'clicked',
      eventCategory: 'SectionPeek',
      eventLabel: 'show more',
      eventLabelValue: undefined,
    },
  })
}

export const ProductBlockComponent = ({
  product,
  showPdpLinkWhenOutOfStock,
  hasClickAndCollect,
  showPaps,
}: {
  product: ProductProps
  showPdpLinkWhenOutOfStock?: boolean
  hasClickAndCollect?: boolean | false
  showPaps?: boolean | false
}) => {
  const screenReaderReviewText = ({ score, total }) =>
    `${score} ${productI18nText.reviewsStarsLabel}
  ${total} ${productI18nText.reviewsLabel}`

  const productI18nText = {
    buyAriaLabel: useFormattableI18nProperty('general.productbuynow.label'),
    buyText: i18n('general.productbuynow.text'),
    quickBuyText: i18n('general.product.quickbuy.text'),
    fromText: i18n('product.price.from.text'),
    reviewsStarsLabel: i18n('reviews.stars.label'),
    reviewsLabel: i18n('reviews.reviews.label'),
    rrpText: i18n('product.price.rrp.text'),
    soldOutText: i18n('general.productsoldout.text'),
    closeI18nText: {
      closeAriaLabel: i18n('general.modal.close.button.arialabel'),
      closeLabel: i18n('general.modal.close.button.label'),
    },
    freeGiftMessage: i18n('product.marketedspecialoffer.freegift.text'),
    swatchTooltipUnavailable: i18n(
      'product.productoptions.swatch.tooltip.unavailable.text',
    ),
    swatchTooltipCloseButton: i18n(
      'product.productoptions.swatch.tooltip.close.text',
    ),
    fulfilmentMethodsText: {
      clickAndCollect: {
        isAvailable: i18n('product.list.clickandcollect.text'),
        isNotAvailable: i18n('product.clickandcollect.unavailable.text'),
      },
      homeDelivery: {
        isAvailable: i18n('product.delivery.home.instock.text'),
        isNotAvailable: i18n('product.delivery.home.unavailable.text'),
        isOutOfStock: i18n('product.delivery.home.outofstock.text'),
        datedDelivery: i18n(
          'basket.item.fulfilment.leadtime',
          product.leadTime?.toString(),
        ),
        nextDayDelivery: i18n('basket.item.fulfilment.nextdaydelivery'),
        oneManDelivery: i18n(
          'product.item.fulfilment.1man.nextdaydeliveryavailable',
        ),
        outOfGaugeDelivery: i18n(
          'product.item.fulfilment.outofgauge.nameddaydeliveryavailable',
        ),
        dynamicDelivery: i18n(
          `product.item.fulfilment.pdp.${product?.weightGroups?.[0]?.toLowerCase()}`,
        ),
      },
      storeDelivery: {
        isAvailable: i18n('product.delivery.store.available.text'),
      },
      orderInStore: {
        isAvailable: i18n(
          'product.item.fulfilment.orderinstore.available.text',
        ),
      },
    },
  }

  return (
    <ProductBlock
      content={product?.content}
      sku={product.sku}
      title={{ value: product.title, useAlternateStyle: false }}
      url={product.url}
      externalIdentifier={product.externalIdentifier}
      image={{
        isAmp: false,
        urls: { largeProduct: product.imageUrl },
      }}
      review={
        product.reviews
          ? {
              starRating: product.reviews.averageScore,
              numberOfReviews: product.reviews.total,
              screenReaderOnlyText: screenReaderReviewText({
                score: product.reviews.averageScore,
                total: product.reviews.total,
              }),
            }
          : undefined
      }
      price={{
        rrpText: productI18nText.rrpText,
        price: product.price,
        rrp: product.rrp,
      }}
      marketedSpecialOffer={
        (showPaps &&
          product.marketedSpecialOffer?.title && {
            title: product.marketedSpecialOffer?.title.content[0].content,
            description:
              product.marketedSpecialOffer?.description?.content[0].content ||
              '',
            i18nText: {
              closeI18nText: productI18nText.closeI18nText,
              freeGiftMessage: productI18nText.freeGiftMessage,
            },
            onlyDisplayOfferBadge: true,
          }) ||
        undefined
      }
      swatch={
        product.colourSwatches
          ? {
              shape: SwatchShape.CIRCLE,
              size: SwatchSize.Small,
              colours: product.colourSwatches,
              i18nText: {
                unavailableText: productI18nText.swatchTooltipUnavailable,
                closeButtonText: productI18nText.swatchTooltipCloseButton,
              },
            }
          : undefined
      }
      button={
        product.inStock
          ? {
              title: productI18nText.buyText,
              ariaLabel: productI18nText.buyAriaLabel(product.title),
              quickBuyTitle: productI18nText.quickBuyText,
              productInStock: product.inStock,
            }
          : {
              title: productI18nText.soldOutText,
              quickBuyTitle: '',
              disabled: !showPdpLinkWhenOutOfStock,
            }
      }
      fulfilmentMethodIconsProps={
        hasClickAndCollect
          ? {
              hasClickAndCollect,
              isCheckStock: product?.isCheckStock ?? false,
              isOrderInStore: product?.isOrderInStore ?? false,
              inStock: product?.inStock!,
              iconsAvailability: product.icons,
              i18nText: productI18nText.fulfilmentMethodsText,
              weightGroups: product.weightGroups,
              inStockLocations: product.inStockLocations,
              leadTime: product.leadTime,
              isBookable: product.isBookable,
            }
          : undefined
      }
      brand={{
        name: product.brand?.name || '',
        imageUrl: product.brand?.imageUrl,
      }}
    />
  )
}

export const SectionPeek = (props: SectionPeekProps) => {
  const i18nText = {
    buttonI18nText: {
      buyAriaLabel: i18n('general.productbuynow.label'),
      buyText: i18n('general.productbuynow.text'),
      quickBuyText: i18n('general.product.quickbuy.text'),
      viewAllText: i18n('general.sectionpeek.viewall.text'),
    },
    reviewsI18nText: {
      reviewsStarsLabel: i18n('reviews.stars.label'),
      reviewsLabel: i18n('reviews.reviews.label'),
    },
  }

  const ProductBlocks = props.products.map((product, key) => {
    return (
      <GridItem key={key} colSpan={[6, 3, 3, 3]}>
        {props.qubitCallbackData ? (
          <ProductRecommendationRail
            qubitCallbackData={props.qubitCallbackData}
            product={product}
            showPdpLinkWhenOutOfStock={props.showPdpLinkWhenOutOfStock}
          />
        ) : (
          <ProductBlockComponent
            product={product}
            showPdpLinkWhenOutOfStock={props.showPdpLinkWhenOutOfStock}
            hasClickAndCollect={props.hasClickAndCollect}
            showPaps={props.showPaps}
          />
        )}
      </GridItem>
    )
  })

  return (
    <div className={props.className}>
      <Title
        size={props.titleProps?.size}
        alignment={props.alignment}
        subtitle={!!props.subtitleProps}
      >
        {props.titleProps?.title}
      </Title>
      {props.subtitleProps && (
        <Subtitle size={props.subtitleProps?.size} alignment={props.alignment}>
          {props.subtitleProps?.subtitle}
        </Subtitle>
      )}
      <ProductContainer horizontalAlignment={props.alignment} columns={12}>
        {ProductBlocks}
        <ButtonWrapper colSpan={12}>
          <ViewMoreButton
            emphasis={props.ButtonProps.emphasis}
            href={props.ButtonProps.link}
            renderedAs={'a'}
            onClick={showMoreEvent}
          >
            {i18nText.buttonI18nText.viewAllText}
          </ViewMoreButton>
        </ButtonWrapper>
      </ProductContainer>
    </div>
  )
}
