import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useRouter } from 'next/compat/router'

import { RecommendationSourceType } from '@thg-commerce/enterprise-components/Qubit'
import { parseQubitPreviewParams } from '@thg-commerce/enterprise-components/Qubit/utils'
import {
  useExperiments,
  useHorizonFeature,
  useHorizonSessionSettings,
  useLogger,
  useSiteConfig,
  useSiteDefinition,
  useTheme,
} from '@thg-commerce/enterprise-core'
import { QubitMode } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'
import { withESI } from '@thg-commerce/enterprise-esi'
import { ProductBlockListData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Content/ProductBlockList'
import {
  Feature,
  GlobalSectionPeek,
  GlobalSectionPeekProductListArgs,
  ProductSort,
  QubitHomePageRecs,
  QueryPageArgs,
  Widget,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { useLandingPage } from '@thg-commerce/enterprise-pages'

import { SectionPeekProducts as SECTION_PEEK_PRODUCT_LIST_QUERY } from '../../graphql/Query/SectionPeekProducts.graphql'
import { BaseWidgetProps } from '../types'

import { QubitRecommendations } from './QubitRecommendations'
import { SectionPeekWrapper } from './SectionPeekWrapper'

export type SectionPeekWidgetRendererProps = BaseWidgetProps &
  (
    | Omit<GlobalSectionPeek, 'query' | 'productList'>
    | Omit<QubitHomePageRecs, 'query' | 'productList'>
  )

type QubitRecsParams =
  | {
      placementId: string
      sourceType:
        | RecommendationSourceType.SECTION_PEEK_WIDGET
        | RecommendationSourceType.QUBIT_HOMEPAGE_RECS_WIDGET
    }
  | undefined

const QubitRecommendationsESI = withESI(
  QubitRecommendations,
  'qubit-widget-section-peek',
)

const qubitRecommendationsEnabled = (qubit, params, experiments) => {
  return (
    qubit?.enabled &&
    params &&
    params.placementId.length > 0 &&
    experiments['thg_recs_vs_qubit_recs'] !== 'v1|thg_recs'
  )
}

export const SectionPeekWidgetRenderer = (
  props: SectionPeekWidgetRendererProps,
) => {
  const vipPricingHorizonFeature = useHorizonFeature(Feature.VipPricingEnabled)
  const { domain } = useSiteDefinition()
  const sessionSettings = useHorizonSessionSettings()
  const logger = useLogger()
  const router = useRouter()
  const { qubit, showPdpLinkWhenOutOfStock, enableVipPrice } = useSiteConfig()
  const landingPage = useLandingPage()
  const clickAndCollectEnabled = useHorizonFeature(Feature.ClickAndCollect)
  const vipPriceEnabled = (enableVipPrice && vipPricingHorizonFeature) || false
  const theme = useTheme()
  const experiments = useExperiments()

  const qubitPreviewParams = parseQubitPreviewParams(
    `https://${domain}${router.asPath}`,
  )

  const params: QubitRecsParams =
    props.__typename === 'GlobalSectionPeek'
      ? {
          placementId: props.qubitPlacementId ?? '',
          sourceType: RecommendationSourceType.SECTION_PEEK_WIDGET,
        }
      : props.__typename === 'qubitHomePageRecs'
      ? {
          placementId: props.placementId ?? '',
          sourceType: RecommendationSourceType.QUBIT_HOMEPAGE_RECS_WIDGET,
        }
      : undefined

  const { loading, data, error } = useQuery<
    { sectionPeekProducts: ProductBlockListData },
    QueryPageArgs &
      Omit<Widget, 'query'> &
      GlobalSectionPeekProductListArgs & {
        clickAndCollectEnabled?: boolean
        vipPriceEnabled: boolean
      }
  >(SECTION_PEEK_PRODUCT_LIST_QUERY, {
    variables: {
      clickAndCollectEnabled,
      vipPriceEnabled,
      path: props.pagePath,
      id: props.id,
      input: {
        ...sessionSettings,
        facets: [],
        sort: ProductSort.Relevance,
        limit: props.numberOfProducts || 4,
        offset: 0,
      },
      ...sessionSettings,
    },
    skip: qubitRecommendationsEnabled(qubit, params, experiments),
  })
  if (qubitRecommendationsEnabled(qubit, params, experiments)) {
    return (
      <QubitRecommendationsESI
        mode={qubitPreviewParams?.mode ?? (qubit!.mode || QubitMode.SAMPLE)}
        placementId={
          qubitPreviewParams?.placementId ?? params?.placementId ?? ''
        }
        previewOptions={{
          campaignId: qubitPreviewParams.campaignId,
          experienceId: qubitPreviewParams.experienceId,
          group: qubitPreviewParams.group,
        }}
        title={props.title || undefined}
        titleAlign={props.titleAlign || undefined}
        url={props.url || undefined}
        subTypes={landingPage.categories}
        showPdpLinkWhenOutOfStock={showPdpLinkWhenOutOfStock}
        source={{
          type:
            params?.sourceType ?? RecommendationSourceType.SECTION_PEEK_WIDGET,
          path: props.pagePath,
          widgetId: parseInt(props.id, 10),
          currency: sessionSettings.currency,
          shippingDestination: sessionSettings.shippingDestination,
        }}
      />
    )
  }

  if (loading) {
    // TODO: REBUILD-7078 - Show a loading skeleton to minimise/eliminate layout shift
    return null
  }

  if (error || !data) {
    logger.error(
      `SectionPeekWidgetRenderer: Failed to load data for product list widget ${error}`,
    )
    return null
  }
  if (!data?.sectionPeekProducts?.length) {
    return null
  }

  return (
    <SectionPeekWrapper
      productList={data.sectionPeekProducts}
      hasClickAndCollect={clickAndCollectEnabled ?? false}
      title={props.title || undefined}
      titleAlign={props.titleAlign || undefined}
      url={props.url || undefined}
      showPdpLinkWhenOutOfStock={showPdpLinkWhenOutOfStock}
      showPaps={theme.widget.sectionPeek.paps.display || false}
    />
  )
}

export default SectionPeekWidgetRenderer
