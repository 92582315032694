import * as React from 'react'

import { ProductBlockListData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Content/ProductBlockList'
import { spacing, styled } from '@thg-commerce/enterprise-theme'
import {
  SectionPeek,
  SectionPeekProps,
} from '@thg-commerce/enterprise-widget-section-peek'
import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'

type SectionPeekWidgetProps = {
  productList: Omit<ProductBlockListData, 'marketedSpecialOffer'>
  qubitCallbackData?: string
  title?: string
  titleAlign?: string
  url?: string
  showPdpLinkWhenOutOfStock?: boolean
  hasClickAndCollect?: boolean | false
  showPaps?: boolean
}

const StyledSectionPeek = styled(SectionPeek)`
  margin: 0 ${spacing(2)};
`

export const SectionPeekWrapper = (props: SectionPeekWidgetProps) => {
  const sectionPeekProps: SectionPeekProps = {
    products: props.productList,
    showPdpLinkWhenOutOfStock: props.showPdpLinkWhenOutOfStock,
    titleProps: props.title
      ? {
          title: props.title || '',
        }
      : undefined,
    alignment: (props.titleAlign || 'center') as HorizontalAlignment,
    ButtonProps: {
      link: props.url || '/',
    },
    qubitCallbackData: props.qubitCallbackData || undefined,
    hasClickAndCollect: props.hasClickAndCollect,
    showPaps: props.showPaps,
  }

  return <StyledSectionPeek {...sectionPeekProps} />
}
