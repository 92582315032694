import * as React from 'react'

import { useLogger, useSiteConfig } from '@thg-commerce/enterprise-core'
import { CallbackSetEvent } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Qubit'
import { sendImpression } from '@thg-commerce/enterprise-components/Qubit/qubitImpressions'

import { ProductProps } from './types'
import { ProductBlockComponent } from './SectionPeek'

type ProductRecommendationRailProps = {
  qubitCallbackData: string
  product: ProductProps
  showPdpLinkWhenOutOfStock?: boolean
}

export const ProductRecommendationRail = (
  props: ProductRecommendationRailProps,
) => {
  const logger = useLogger()
  const { qubit } = useSiteConfig()

  return (
    <div
      onClick={() => {
        sendImpression({
          logger,
          eventType: CallbackSetEvent.CLICK_THROUGH,
          callbackData: props.qubitCallbackData,
          callbackURL: qubit?.callbackURL,
        })
        sendImpression({
          logger,
          sku: props.product.sku,
          eventType: CallbackSetEvent.CLICK_THROUGH,
          callbackData: props.qubitCallbackData,
          callbackURL: qubit?.callbackURL,
        })
      }}
      role="button"
    >
      <ProductBlockComponent
        product={props.product}
        showPdpLinkWhenOutOfStock={props.showPdpLinkWhenOutOfStock}
      />
    </div>
  )
}
