import * as React from 'react'
import handleViewport from 'react-in-viewport'

import {
  sendImpression,
  sendPlacementImpressionEvents,
} from '@thg-commerce/enterprise-components/Qubit/qubitImpressions'
import { fetchFallbackData } from '@thg-commerce/enterprise-components/Qubit/QubitRecommendations/Fallback'
import {
  QubitHomePageRecsWidgetSource,
  SectionPeekWidgetSource,
} from '@thg-commerce/enterprise-components/Qubit/QubitRecommendations/types'
import {
  useLogger,
  useSiteConfig,
  withCacheConfiguration,
} from '@thg-commerce/enterprise-core'
import { QubitMode } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'
import { ESIComponent, withESIWrapper } from '@thg-commerce/enterprise-esi'
import { ESIRequestContext } from '@thg-commerce/enterprise-esi/src/types'
import { ProductBlockListData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Content/ProductBlockList'
import {
  PlacementContentType,
  PreviewOptions,
  QubitViewType,
  RecommendationsContent,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Qubit'
import {
  Country,
  Currency,
  QubitSectionPeekProductsQueryVariables,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { PlacementContent as QUBIT_QUERY } from '@thg-commerce/enterprise-network/src/graphql/Query/Qubit/PlacementContent.graphql'

import { SectionPeekProducts as QUBIT_PRODUCT_LIST_QUERY } from '../../graphql/Query/QubitSectionPeekProducts.graphql'

import { SectionPeekWrapper } from './SectionPeekWrapper'

interface QubitRecommendationProps {
  placementId: string
  mode: QubitMode
  source: SectionPeekWidgetSource | QubitHomePageRecsWidgetSource
  previewOptions?: PreviewOptions
  title?: string
  titleAlign?: string
  url?: string
  subTypes?: string[]
  showPdpLinkWhenOutOfStock?: boolean
}

interface QubitRecommendationsInitialProps {
  products?: ProductBlockListData
  qubitCallbackData?: string
}

const ProductsContainer = ({
  forwardedRef,
  children,
}: {
  forwardedRef: React.RefObject<HTMLDivElement>
  children: React.ReactNode
}) => {
  return <div ref={forwardedRef}>{children}</div>
}

const QubitRecommendations: ESIComponent<
  QubitRecommendationProps & QubitRecommendationsInitialProps,
  QubitRecommendationsInitialProps
> = (props) => {
  const siteConfig = useSiteConfig()
  const logger = useLogger()
  const impressionEmittedRef = React.useRef(false)

  const ProductsContainerInViewPort = React.useMemo(
    () => handleViewport(ProductsContainer, {}, { disconnectOnLeave: true }),
    [],
  )

  const onEnterViewport = () => {
    if (impressionEmittedRef.current) {
      return
    }
    sendPlacementImpressionEvents({
      logger,
      sendPlacementLevelImpression: sendImpression,
      productId: props.products?.map((product) => product.sku),
      callbackData: props.qubitCallbackData,
      callbackURL: siteConfig.qubit?.callbackURL,
    })
    impressionEmittedRef.current = true
  }

  if (!siteConfig.qubit?.enabled || !props.products?.length) {
    return null
  }

  return (
    <ProductsContainerInViewPort onEnterViewport={onEnterViewport}>
      <SectionPeekWrapper
        productList={props.products}
        qubitCallbackData={props.qubitCallbackData}
        title={props.title}
        titleAlign={props.titleAlign}
        url={props.url}
        showPdpLinkWhenOutOfStock={props.showPdpLinkWhenOutOfStock}
      />
    </ProductsContainerInViewPort>
  )
}

QubitRecommendations.getInitialProps = withCacheConfiguration<
  QubitRecommendationsInitialProps,
  ESIRequestContext<QubitRecommendationsInitialProps & QubitRecommendationProps>
>(async (context) => {
  if (context.props.products) {
    return {
      products: context.props.products,
      qubitCallbackData: context.props.qubitCallbackData,
      cache: context.props.cache,
    }
  }
  try {
    if (!context.req.cookies['_qubitTracker']) {
      return {
        ...context.props,
        products: await fetchFallbackData(
          context.props.source,
          context.apolloClient,
        ),
        qubitCallbackData: undefined,
        cache: {
          ttl: 0,
        },
      }
    }

    const defaultLocale = context.config.publicRuntimeConfig.siteDefinition.defaultLocale
      .replace(/_/g, '-')
      .toLowerCase()

    const currency =
      context.req.config.sessionSettings?.currency ||
      context.config.publicRuntimeConfig.siteDefinition.defaultCurrency

    const { data: qubitData } = await context.apolloClient.query<{
      qubitPlacements: {
        callbackData: string
        content: {
          [PlacementContentType.RECOMMENDATIONS]: RecommendationsContent
        }
      }
    }>({
      query: QUBIT_QUERY,
      variables: {
        placementId: context.props.placementId,
        mode: context.props.mode,
        previewOptions: {
          experienceId: context.props.previewOptions?.experienceId,
          campaignId: context.props.previewOptions?.campaignId,
          group: context.props.previewOptions?.group,
        },
        attributes: {
          visitor: {
            id: context.req.cookies['_qubitTracker'],
            url: `${context.config.publicRuntimeConfig.siteDefinition.domain}${context.req.browserUrl}`,
          },
          user: {},
          product: { id: undefined },
          view: {
            currency,
            type: QubitViewType.CATEGORY,
            subtypes: context.props.subTypes || [],
            language: defaultLocale,
          },
        },
        resolveVisitorState: true,
        recsPreFilter: false,
      },
    })

    const content =
      qubitData?.qubitPlacements?.content?.[
        PlacementContentType.RECOMMENDATIONS
      ]

    if (!content?.recs.length) {
      return {
        ...context.props,
        products: await fetchFallbackData(
          context.props.source,
          context.apolloClient,
        ),
        qubitCallbackData: qubitData?.qubitPlacements?.callbackData,
        cache: {
          ttl: 0,
        },
      }
    }

    const skus = content.recs.map((product) => product.id)

    const { data } = await context.apolloClient.query<
      {
        qubitSectionPeekProducts: ProductBlockListData
      },
      QubitSectionPeekProductsQueryVariables
    >({
      query: QUBIT_PRODUCT_LIST_QUERY,
      variables: {
        skus,
        currency: currency as Currency,
        shippingDestination: (context.req.config.sessionSettings
          ?.shippingDestination ||
          context.config.publicRuntimeConfig.siteDefinition
            .defaultSessionSettings.shippingDestination) as Country,
      },
    })

    return {
      ...context.props,
      title: content.headline ?? context.props.title,
      products: data.qubitSectionPeekProducts,
      qubitCallbackData: qubitData?.qubitPlacements?.callbackData,
      cache: {
        ttl: 0,
      },
    }
  } catch (error) {
    console.error(`Failed fetching qubit widget data with error: ${error}`)

    try {
      return {
        ...context.props,
        products: await fetchFallbackData(
          context.props.source,
          context.apolloClient,
        ),
        qubitCallbackData: undefined,
        cache: {
          ttl: 0,
        },
      }
    } catch (fallbackError) {
      console.error(
        `Failed fetching qubit fallback products with error: ${fallbackError}`,
      )

      return {
        ...context.props,
        products: [],
        qubitCallbackData: undefined,
        cache: {
          ttl: 0,
        },
      }
    }
  }
})

const ESIQubitRecommendations = withESIWrapper(QubitRecommendations)

export { ESIQubitRecommendations as QubitRecommendations }

export default ESIQubitRecommendations
